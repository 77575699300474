import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  getGraphFiltersFromSearchParams,
  getSearchParamsFromGraphFilters
} from 'app/components/utils/url-utils';
import {
  CloudApiItem,
  CloudFiltersState,
  CloudGroupBy,
  CloudRequestCountFilter,
  CountHistogramResponse,
  GetCloudKeyResponseType
} from 'app/build-api';
import { buildApi, DefaultResponse, GetResponseType } from 'app/build-api/types';
import { EntityCountChart } from '../../common/charts/entity-count-chart';
import {
  ChartLegendsType,
  getChartValuesDataWithLabels,
  getRequestCount,
  prepareApiListDataToChartLegends,
  prepareKeyListDataToChartLegends,
  prepareStatusListDataToChartLegends
} from '../../common/charts/chart-formatters';
import { cloudStatuses } from '../constants/constants';
import { FailedToFetch } from 'app/components/table/table-error';
import { ChartGraphFilter, RequestCountChartHeader } from '../../common/charts/chart-header';

interface RequestCountContainerProps {
  filters: CloudFiltersState;
  apiList: GetResponseType<CloudApiItem[]>;
  keyList: GetResponseType<GetCloudKeyResponseType>;
}

export const RequestCountContainer = ({
  filters,
  apiList,
  keyList
}: RequestCountContainerProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [graphFilters, setGraphFilters] = useState<CloudRequestCountFilter>(
    getGraphFiltersFromSearchParams(searchParams)
  );
  const [chartLegendsData, setChartLegendsData] = useState<ChartLegendsType[]>([]);
  const [histogramData, setHistogramData] =
    useState<GetResponseType<CountHistogramResponse>>(DefaultResponse);

  useEffect(() => {
    (async () => {
      const res = await buildApi.getCloudLogsRequestCountHistogram(filters, graphFilters);
      setHistogramData(res);
    })();
  }, [filters, graphFilters]);

  const yAxisDataById = histogramData.data?.histogram ? getRequestCount(histogramData.data) : [];

  const formattedApiListData = prepareApiListDataToChartLegends(apiList?.data || []);
  const formattedKeyListData = prepareKeyListDataToChartLegends(keyList?.data?.data || []);
  const formattedStatusListData = prepareStatusListDataToChartLegends(cloudStatuses);

  useEffect(() => {
    switch (graphFilters.groupBy) {
      case CloudGroupBy.Api:
        setChartLegendsData(formattedApiListData);
        break;
      case CloudGroupBy.Key:
        setChartLegendsData(formattedKeyListData);
        break;
      case CloudGroupBy.Status:
        setChartLegendsData(formattedStatusListData);
        break;
      default:
        setChartLegendsData(formattedApiListData);
        break;
    }
  }, [graphFilters.groupBy, graphFilters.period, apiList, keyList]);

  const requestCountChartData = getChartValuesDataWithLabels(chartLegendsData, yAxisDataById);

  const handleFiltersChange = (newGraphFilters: Partial<ChartGraphFilter>) => {
    const newState = {
      ...graphFilters,
      ...newGraphFilters
    };
    setSearchParams((prevParams) => {
      const currentParams = new URLSearchParams(prevParams);
      return getSearchParamsFromGraphFilters(currentParams, newState as CloudRequestCountFilter);
    });
    setGraphFilters(newState as CloudRequestCountFilter);
  };

  const loading = apiList.loading || keyList.loading || histogramData.loading;

  const error = apiList.error || keyList.error || histogramData.error;

  return (
    <EntityCountChart
      chartHeader={
        <RequestCountChartHeader
          totalRequests={histogramData?.data?.total || 0}
          graphFilters={graphFilters}
          onFiltersChange={handleFiltersChange}
        />
      }
      title={'Request Count'}
      yAxisLabelText={'Request count'}
      data={requestCountChartData}
      loading={loading}
      error={error ? <FailedToFetch error={error} /> : null}
    />
  );
};
