import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import styled, { useTheme } from 'styled-components';
import { BodyText, FlexRow, SvgIcon, useClickAway } from '@make-software/cspr-ui';
import {
  checkLengthRange,
  invalidEmailMessage,
  invalidNameMessage,
  validateEmail,
  validateNumbersOnly
} from 'app/validators';
import {
  ButtonsContainer,
  centerModalStyles,
  ErrorText,
  ErrorTextContainer,
  ModalContainer,
  StyledCaption,
  StyledCaptionText,
  StyledConfirmButton,
  StyledDismissButton,
  StyledInput
} from 'app/components/home/components/create-modal-styles';
import { ErrorWindow } from 'app/components/common/error-window/error-window';
import NotFoundIcon from 'assets/logos/no-found.svg';
import { ErrorResult } from 'app/build-api/types';
import { AccessItem, InviteUserRequestType } from 'app/build-api';
import { ExpectedErrors } from 'app/components/home/components/users/user-container';
import { UserAccessList } from 'app/components/home/components/users/components/user-access-list';

const InviteUserModalContainer = styled(ModalContainer)(({ theme }) =>
    theme.withMedia({
      overflowY: 'scroll',
      maxHeight: '70svh'
    })
);

export interface InviteUserWindowProps {
  isOpen: boolean;
  title: string;
  inputPlaceholder?: string;
  confirmLabel: string;
  onConfirm: (data: InviteUserRequestType) => void;
  dismissLabel?: string;
  onDismiss: () => void;
  expectedError?: ExpectedErrors | undefined;
  unexpectedError?: ErrorResult | null;
  portalClass?: string;
}

export const InviteUserWindow = ({
  isOpen,
  title,
  confirmLabel,
  onConfirm,
  dismissLabel,
  onDismiss,
  expectedError,
  unexpectedError,
  portalClass = 'portal'
}: InviteUserWindowProps) => {
  const theme = useTheme();
  const modalStyle = {
    overlay: {
      backgroundColor: theme.styleguideColors.backgroundOverlay,
      zIndex: 15
    },
    content: {
      ...centerModalStyles,
      ...{
        border: 'none',
        backgroundColor: theme.styleguideColors.backgroundPrimary,
        borderTop: `4px solid ${theme.styleguideColors.contentRed}`,
        borderColor: theme.styleguideColors.contentRed,
        boxShadow: '0px 16px 48px rgba(26, 25, 25, 0.2)'
      }
    }
  };

  const defaultUserAccess = [
    AccessItem.ClickKeyManagement,
    AccessItem.CloudKeyManagement,
    AccessItem.Statistics
  ];

  const [email, setEmail] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [userAccess, setUserAccess] = useState<AccessItem[]>(defaultUserAccess);
  const [isRoleSelected, setIsRoleSelected] = useState<boolean>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<Record<'name' | 'email', string | string>>({
    name: '',
    email: ''
  });

  const clearForm = () => {
    setName('');
    setEmail('');
    setUserAccess(defaultUserAccess);
    setFormErrors({
      ...formErrors,
      email: '',
      name: ''
    });
  };

  useEffect(() => {
    setShowModal(isOpen);
    clearForm();
  }, [isOpen]);

  const { ref } = useClickAway({
    callback: () => {
      !isRoleSelected && clearForm();
      !isRoleSelected && onDismiss();
      setIsRoleSelected(false);
    }
  });

  const handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    setEmail(val);
  };

  const handleEmailOnBlur = () => {
    setFormErrors({
      ...formErrors,
      email: !validateEmail(email) ? invalidEmailMessage : ''
    });
  };

  const handleName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    setName(val);
  };

  const handleNameOnBlur = () => {
    setFormErrors({
      ...formErrors,
      name: validateNumbersOnly(name) ? invalidNameMessage : checkLengthRange(name)
    });
  };

  const handleAccessChange = (access: AccessItem[]) => {
    setUserAccess(access);
    setIsRoleSelected(true);
  };

  const handleConfirm = () => {
    if (!email.length || !name.length) {
      setFormErrors({
        ...formErrors,
        email: !email.length ? 'This field is required' : '',
        name: !name.length
          ? 'This field is required'
          : validateNumbersOnly(name)
            ? invalidNameMessage
            : ''
      });
      return;
    }

    onConfirm &&
      !formErrors.name &&
      !formErrors.email &&
      onConfirm({ full_name: name, email, access_list: userAccess });
  };

  const handleOnDismiss = () => {
    clearForm();
    onDismiss();
  };

  const showAppropriateError = () => {
    let specificError = '';
    expectedError &&
      Object.values(expectedError).some((item) => {
        if (Boolean(item)) {
          specificError = item;
        }
      });

    return (
      specificError && (
        <ErrorTextContainer justify={'center'}>
          <ErrorText size={3}>{specificError}</ErrorText>
        </ErrorTextContainer>
      )
    );
  };

  return (
    <>
      {showModal && (
        <ReactModal
          isOpen={showModal}
          style={modalStyle}
          shouldCloseOnEsc
          shouldCloseOnOverlayClick
          portalClassName={portalClass}
        >
          <InviteUserModalContainer ref={ref} gap={20}>
            {unexpectedError ? (
              <ErrorWindow
                title={unexpectedError.code || ''}
                information={unexpectedError.message}
                withModal
                icon={<SvgIcon src={NotFoundIcon} width={219} height={125} />}
              />
            ) : (
              <>
                <StyledCaption>
                  <StyledCaptionText size={1} scale="lg">
                    {title}
                  </StyledCaptionText>
                </StyledCaption>
                <FlexRow justify="center">
                  <StyledInput
                    value={name}
                    label={<BodyText size={1}>Full name</BodyText>}
                    placeholder="User name"
                    onChange={handleName}
                    onBlur={handleNameOnBlur}
                    error={!!formErrors.name}
                    validationText={formErrors.name}
                  />
                </FlexRow>
                <FlexRow justify="center">
                  <StyledInput
                    value={email}
                    label={<BodyText size={1}>Email</BodyText>}
                    placeholder="User email"
                    onChange={handleEmail}
                    onBlur={handleEmailOnBlur}
                    error={!!formErrors.email}
                    validationText={formErrors.email}
                  />
                </FlexRow>

                {showAppropriateError()}

                <UserAccessList currentAccessList={userAccess} onSelectRole={handleAccessChange} />

                <ButtonsContainer justify={'space-between'}>
                  {dismissLabel && (
                    <StyledDismissButton color={'utility'} onClick={handleOnDismiss}>
                      {dismissLabel}
                    </StyledDismissButton>
                  )}
                  <StyledConfirmButton onClick={handleConfirm}>{confirmLabel}</StyledConfirmButton>
                </ButtonsContainer>
              </>
            )}
          </InviteUserModalContainer>
        </ReactModal>
      )}
    </>
  );
};

export default InviteUserWindow;
