import {
  ClickApiGraphFilter,
  clickFiltersSearchParams,
  ClickGroupBy,
  ClickSessionCountGraphFilter,
  ClickUniqueAccountsCountGraphFilter,
  cloudFiltersSearchParams,
  CloudFiltersState,
  CloudGroupBy,
  CloudInputFilters,
  CloudRequestCountFilter,
  DateRange,
  DateRangeKey
} from 'app/build-api';
import { Pagination } from 'app/build-api/types';
import { Period } from 'app/build-api/statistics/common';
import { getDefaultFilters } from '../statistics/components/cloud/utils/initial-filter-state-getter';
import { formatDateToISODate, getDateRangeFromSearchParams } from './date-time';

export const getSearchParamsFromFilters = (
  searchParams: URLSearchParams,
  filters: Record<string, any>
): URLSearchParams => {
  Object.entries(filters).forEach(([key, value]) => {
    if (cloudFiltersSearchParams[key]) {
      const paramKey = cloudFiltersSearchParams[key];

      if (key === CloudInputFilters.endpoints) {
        value = value.map((i: string) => i.replace(',', '~|~'));
      }

      const paramValue = value.join(',');
      return value && value.length
        ? searchParams.set(paramKey, paramValue)
        : searchParams.delete(paramKey);
    }

    if (clickFiltersSearchParams[key]) {
      const paramKey = clickFiltersSearchParams[key];

      const paramValue = value.join(',');
      return value && value.length
        ? searchParams.set(paramKey, paramValue)
        : searchParams.delete(paramKey);
    }

    if (key === DateRangeKey) {
      const val = value as DateRange;
      searchParams.set('from', formatDateToISODate(val.from));
      searchParams.set('to', formatDateToISODate(val.to));
    }
  });

  return searchParams;
};

export const getCloudFiltersFromSearchParams = (
  searchParams: URLSearchParams
): CloudFiltersState => {
  let filters = getDefaultFilters();

  Object.keys(filters as CloudFiltersState).forEach((key) => {
    if (searchParams.has(cloudFiltersSearchParams[key])) {
      let filterValue = (searchParams.get(cloudFiltersSearchParams[key]) || '').split(',');

      if (key === CloudInputFilters.endpoints) {
        filterValue = filterValue.map((i: string) => i.replace('~|~', ','));
      }

      filters = {
        ...filters,
        [key]: filterValue
      };
    }
  });

  filters[DateRangeKey] = getDateRangeFromSearchParams(searchParams);

  return filters;
};

export const getPaginationFromSearchParams = (
  searchParams: URLSearchParams,
  itemLimit?: number
): Pagination => {
  const pagination = {
    currentPage: parseInt(searchParams.get('page') as string) || 1,
    pageSize: parseInt(searchParams.get('pageSize') as string) || 10
  };
  if (itemLimit && itemLimit < pagination.pageSize * pagination.currentPage) {
    searchParams.set('page', '1');
    searchParams.set('pageSize', '10');
    return { currentPage: 1, pageSize: 10 };
  }
  return pagination;
};

export const getGraphFiltersFromSearchParams = (
  searchParams: URLSearchParams
): CloudRequestCountFilter => {
  return {
    groupBy: (searchParams.get('group_by') as CloudGroupBy) || CloudGroupBy.Api,
    period: (searchParams.get('period') as Period) || Period.Day
  };
};

export const getSearchParamsFromGraphFilters = (
  searchParams: URLSearchParams,
  filters: CloudRequestCountFilter | ClickApiGraphFilter
): URLSearchParams => {
  searchParams.set('group_by', filters.groupBy);
  searchParams.set('period', filters.period);
  return searchParams;
};

export const getSearchParamsFromSessionGraphFilters = (
  searchParams: URLSearchParams,
  filters: ClickSessionCountGraphFilter
): URLSearchParams => {
  searchParams.set('clickSessionsCountGroupBy', filters.clickSessionsCountGroupBy);
  searchParams.set('clickSessionsCountPeriod', filters.clickSessionsCountPeriod);
  return searchParams;
};

export const getClickSessionGraphFiltersFromSearchParams = (
  searchParams: URLSearchParams
): ClickSessionCountGraphFilter => {
  return {
    clickSessionsCountGroupBy:
      (searchParams.get('clickSessionsCountGroupBy') as ClickGroupBy) || ClickGroupBy.App,
    clickSessionsCountPeriod: (searchParams.get('clickSessionsCountPeriod') as Period) || Period.Day
  };
};

export const getSearchParamsFromUniqueGraphFilters = (
  searchParams: URLSearchParams,
  filters: ClickUniqueAccountsCountGraphFilter
): URLSearchParams => {
  searchParams.set('clickUniqueAccountsCountGroupBy', filters.clickUniqueAccountsCountGroupBy);
  searchParams.set('clickUniqueAccountsCountPeriod', filters.clickUniqueAccountsCountPeriod);
  return searchParams;
};

export const getClickUniqueGraphFiltersFromSearchParams = (
  searchParams: URLSearchParams
): ClickUniqueAccountsCountGraphFilter => {
  return {
    clickUniqueAccountsCountGroupBy:
      (searchParams.get('clickUniqueAccountsCountGroupBy') as ClickGroupBy) || ClickGroupBy.App,
    clickUniqueAccountsCountPeriod:
      (searchParams.get('clickUniqueAccountsCountPeriod') as Period) || Period.Day
  };
};
