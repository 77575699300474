import { ClickFilterState, ClickApiGraphFilter } from './filter';
import { CountHistogramResponse } from '../common';
import {
  getSearchParamsFromFilters,
  getSearchParamsFromGraphFilters
} from 'app/components/utils/url-utils';
import { GetResponseType } from 'app/build-api/types';
import { decodeJWT } from 'app/components/utils/utils';
import { AUTH_TOKEN, WITH_AUTH } from 'app/constants';
import useApi from 'app/build-api/hooks/useApi';

export const getClickSessionCountHistogram = async (
  filters: ClickFilterState,
  graphFilter: ClickApiGraphFilter
): Promise<GetResponseType<CountHistogramResponse>> => {
  const searchParams = new URLSearchParams();
  const filterParams = getSearchParamsFromFilters(searchParams, filters);
  const graphParams = getSearchParamsFromGraphFilters(filterParams, graphFilter);
  const decodedJwt = JSON.parse(decodeJWT(localStorage.getItem(AUTH_TOKEN) || ''));

  return await useApi(
    `${process.env.REACT_APP_CSPRBUILD_API_ENDPOINT}/organizations/${decodedJwt.organizationID}/click-logs/sessions-count?${graphParams.toString()}`,
    'GET',
    null,
    {},
    WITH_AUTH
  );
};
