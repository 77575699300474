export const validateEmail = (email: string) => {
  return !!(
    email &&
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  );
};

export const validateDomain = (domain: string) =>
  !domain.toLowerCase().match(/^[a-z0-9\d*]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,63}$/)
    ? 'Incorrect format. Please use example.com or www.example.com'
    : '';

export const validateNumbersOnly = (data: string) =>
  !!(data && String(data).toLowerCase().match(/\d+/));

export const checkLengthRange = (str: string) =>
  str
    ? str.length < 3
      ? 'Invalid length. Minimum length should be at least 3 characters'
      : str.length > 63
        ? 'Invalid length. Max length should be 63 characters'
        : ''
    : '';

export const checkMaxLength = (name: string) =>
  name.length > 63 ? 'Invalid length. Max length should be 63 characters' : '';

export const invalidEmailMessage = 'This isn’t a correct email format';
export const invalidPasswordMessage = 'Password doesn’t meet our requirements';
export const invalidNameMessage = 'Name can’t contain digits or special characters';
