import { ClickFilterState, ClickInputFilters, DateRangeKey } from 'app/build-api';
import { DropdownValue } from 'app/components/common/multiselect-input/dropdown';
import { getCurrentAndThirtyDaysAgoDate } from 'app/components/utils/date-time';

export type SelectedCloudFilterType = DropdownValue & { id: string | number };

export interface SelectedClickFiltersState {
  [ClickInputFilters.apps]: SelectedCloudFilterType[];
  [ClickInputFilters.providers]: SelectedCloudFilterType[];
}

export const getDefaultFilters = (): ClickFilterState => {
  const { now, thirtyDaysAgo } = getCurrentAndThirtyDaysAgoDate();

  return {
    [DateRangeKey]: { from: thirtyDaysAgo, to: now },
    [ClickInputFilters.apps]: [],
    [ClickInputFilters.providers]: []
  };
};

export const getDefaultSelectedFilters = (): SelectedClickFiltersState => {
  return {
    [ClickInputFilters.apps]: [],
    [ClickInputFilters.providers]: []
  };
};

type GetPreselectedFiltersArgs = {
  [ClickInputFilters.apps]: SelectedCloudFilterType[];
  [ClickInputFilters.providers]: SelectedCloudFilterType[];
  filters: ClickFilterState;
};

export const getPreselectedFilters = ({
  filters,
  apps,
  providers
}: GetPreselectedFiltersArgs): SelectedClickFiltersState => {
  const preselectedKeys = apps.filter((one) => filters.apps.includes(one.id.toString()));
  const preselectedApis = providers.filter((one) => filters.providers.includes(one.id.toString()));

  return {
    apps: preselectedKeys,
    providers: preselectedApis
  };
};
