import { CloudFiltersState, CloudInputFilters, DateRangeKey } from 'app/build-api';
import { DropdownValue } from '@make-software/cspr-ui/dist/lib/components/dropdown/dropdown';
import { getCurrentAndThirtyDaysAgoDate } from 'app/components/utils/date-time';

export interface SelectedCloudFiltersState {
  [CloudInputFilters.keys]: SelectedCloudFilterType[];
  [CloudInputFilters.apis]: SelectedCloudFilterType[];
  [CloudInputFilters.endpoints]: SelectedCloudFilterType[];
  [CloudInputFilters.statuses]: SelectedCloudFilterType[];
}

export type SelectedCloudFilterType = DropdownValue & { id: string | number };

export const getDefaultFilters = (): CloudFiltersState => {
  const { now, thirtyDaysAgo } = getCurrentAndThirtyDaysAgoDate();

  return {
    [DateRangeKey]: { from: thirtyDaysAgo, to: now },
    [CloudInputFilters.keys]: [],
    [CloudInputFilters.apis]: [],
    [CloudInputFilters.endpoints]: [],
    [CloudInputFilters.statuses]: []
  };
};

export const getDefaultSelectedFilters = (): SelectedCloudFiltersState => {
  return {
    [CloudInputFilters.keys]: [],
    [CloudInputFilters.apis]: [],
    [CloudInputFilters.endpoints]: [],
    [CloudInputFilters.statuses]: []
  };
};

type GetPreselectedFiltersArgs = {
  keys: SelectedCloudFilterType[];
  apis: SelectedCloudFilterType[];
  statuses: SelectedCloudFilterType[];
  filters: CloudFiltersState;
};

export const getPreselectedFilters = ({
  filters,
  keys,
  apis,
  statuses
}: GetPreselectedFiltersArgs): SelectedCloudFiltersState => {
  const preselectedKeys = keys.filter((key) => filters.keys.includes(key.id.toString()));
  const preselectedApis = apis.filter((api) => filters.apis.includes(api.id.toString()));
  const preselectedEndpoints = filters.endpoints.map((endpoint) => ({
    id: endpoint,
    label: endpoint,
    value: endpoint,
    chipLabel: endpoint
  }));
  const preselectedStatuses = statuses.filter((status) =>
    filters.statuses.includes(status.id.toString())
  );

  return {
    keys: preselectedKeys,
    apis: preselectedApis,
    statuses: preselectedStatuses,
    endpoints: preselectedEndpoints
  };
};
