import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  BodyText,
  Button,
  Checkbox,
  CheckboxFontSize,
  FlexColumn,
  FlexRow,
  HeaderText,
  Input,
  InputValidationType,
  SvgIcon,
  useMatchMedia
} from '@make-software/cspr-ui';
import AccountLogo from 'assets/logos/creating-account.svg';
import { SIGN_IN_PATH } from 'app/route/paths';
import {
  checkLengthRange,
  invalidEmailMessage,
  invalidNameMessage,
  invalidPasswordMessage,
  validateEmail,
  validateNumbersOnly
} from 'app/validators';
import { PasswordHints } from 'app/components/common/password-hints/password-hints';
import { PASSWORD_LENGTH, ROW_GAP_LG, ROW_GAP_MD, ROW_GAP_SM } from 'app/constants';
import { SignUpRequestData } from 'app/build-api';

const ERROR_COLOR = '#E6332A';

interface SignUpFormProps {
  onSubmit: (data: SignUpRequestData, reCaptchaToken: string) => Promise<void>;
  expectedError: string;
  recaptchaError: string;
}

const ContentWindow = styled(FlexColumn)(({ theme }) =>
  theme.withMedia({
    display: 'flex',
    padding: '48px',
    gap: ['28px', '40px', '40px'],
    width: ['300px', '350px', '577px'],
    borderRadius: '4px',
    boxShadow: '0px 2px 4px 0px rgba(132, 134, 140, 0.12)',
    backgroundColor: theme.styleguideColors.backgroundPrimary
  })
);

const StyledTab = styled(Button)(({ theme }) =>
  theme.withMedia({
    borderRadius: '0px',
    height: '32px',
    fontSize: ['11px', '14px', '14px']
  })
);

const StyledA = styled(Link)(({ theme }) => ({
  color: theme.styleguideColors.contentBlue
}));

const StyledRecaptcha = styled(ReCAPTCHA)<{ recaptchaError?: string }>(
  ({ theme, recaptchaError }) =>
    theme.withMedia({
      'div div > div': {
        border: recaptchaError && `1px solid ${theme.styleguideColors.borderRed}`,
        height: recaptchaError && '76px'
      }
    })
);

const StyledErrorText = styled(BodyText)(({ theme }) =>
  theme.withMedia({
    fontSize: '11px',
    position: 'relative',
    bottom: '42px',
    height: '0',
    color: theme.styleguideColors.borderRed
  })
);

const StyledErrorCheckboxText = styled(StyledErrorText)(({ theme }) =>
  theme.withMedia({
    marginTop: '12px'
  })
);

export const SignUpForm = ({ onSubmit, expectedError, recaptchaError }: SignUpFormProps) => {
  const [activeCompanyButton, setActiveCompanyButton] = useState<boolean>(true);
  const [activeIndividualButton, setActiveIndividualButton] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [companyName, setCompanyName] = useState<string>('');
  const [reCaptcha, setReCaptcha] = useState('');
  const [checkboxValue, setCheckboxValue] = useState(false);
  const [checkboxErrorText, setCheckboxErrorText] = useState('');
  const [reCaptchaErrorText, setRecaptchaErrorText] = useState('');
  const [reCaptchaApiError, setRecaptchaApiError] = useState('');
  const [formErrors, setFormErrors] = useState<
    Record<'name' | 'email' | 'password' | 'companyName', null | string | null | string>
  >({
    name: null,
    email: '',
    password: null,
    companyName: ''
  });

  const screenType = useMatchMedia(['mobile', 'tablet', 'desktop'], []);
  let captcha: any;

  useEffect(() => {
    if (expectedError) {
      setFormErrors({
        ...formErrors,
        email: expectedError
      });
    }
  }, [expectedError]);

  useEffect(() => {
    if (recaptchaError) {
      setRecaptchaApiError(recaptchaError);
      captcha.reset();
      setRecaptchaApiError('');
      setReCaptcha('');
    }
  }, [recaptchaError]);

  const handleCompanyAccount = () => {
    setActiveCompanyButton(true);
    setActiveIndividualButton(false);
  };
  const handleIndividualAccount = () => {
    setCompanyName('');
    setActiveCompanyButton(false);
    setActiveIndividualButton(true);
  };

  const handleName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    setName(name);
  };

  const handleOnBlurName = () => {
    setFormErrors({
      ...formErrors,
      name: validateNumbersOnly(name) ? invalidNameMessage : checkLengthRange(name)
    });
  };
  const handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value;
    setEmail(email);
  };

  const handleOnBlurEmail = () => {
    setFormErrors({
      ...formErrors,
      email: !validateEmail(email) ? invalidEmailMessage : ''
    });
  };

  const handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const pass = event.target.value;
    setPassword(pass);
  };

  const handleOnBlurPassword = () => {
    setFormErrors({
      ...formErrors,
      password: password.length < PASSWORD_LENGTH ? invalidPasswordMessage : ''
    });
  };

  const handleCompanyName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const companyName = event.target.value;
    setCompanyName(companyName);
  };

  const handleOnBlurCompanyName = () => {
    setFormErrors({
      ...formErrors,
      companyName: checkLengthRange(companyName)
    });
  };

  const handleReCaptcha = (value: any) => {
    setReCaptcha(value);
    setRecaptchaErrorText('');
  };

  const handleCheckbox = (value: boolean) => {
    setCheckboxValue(value);
    setCheckboxErrorText('');
  };

  const handleSubmit = () => {
    const isCompanyAccount = activeCompanyButton ? !formErrors.companyName : true;

    if (
      !password.length ||
      !name.length ||
      !email.length ||
      (activeCompanyButton && !companyName.length)
    ) {
      setFormErrors({
        ...formErrors,
        email: !email.length ? 'Please enter email address' : '',
        name: !name.length ? 'Please enter name' : '',
        password: !password.length ? 'Please enter password' : '',
        companyName: activeCompanyButton
          ? !companyName.length
            ? 'Please enter company name'
            : ''
          : ''
      });
      return;
    }

    if (!checkboxValue) {
      setCheckboxErrorText(
        'Please confirm that you have read and understood our Privacy Policy and Terms of Service'
      );
    }

    if (!reCaptcha) {
      setRecaptchaErrorText('Please fill reCaptcha');
    }

    const isValidForm =
      Boolean(!formErrors.password) &&
      Boolean(!formErrors.name) &&
      Boolean(!formErrors.email) &&
      Boolean(isCompanyAccount) &&
      Boolean(reCaptcha.length) &&
      checkboxValue;

    isValidForm &&
      onSubmit &&
      onSubmit(
        {
          email,
          password,
          full_name: name,
          is_individual_person: activeIndividualButton,
          organization_name: companyName
        },
        reCaptcha
      );
  };

  return (
    <ContentWindow>
      <SvgIcon src={AccountLogo} width={289} height={114} />
      <HeaderText size={3} scale={'md'}>
        Create free account
      </HeaderText>
      <FlexRow gap={2}>
        <StyledTab
          color={activeCompanyButton ? 'primaryBlue' : 'secondaryBlue'}
          onClick={handleCompanyAccount}
        >
          Company account
        </StyledTab>
        <StyledTab
          color={activeIndividualButton ? 'primaryBlue' : 'secondaryBlue'}
          onClick={handleIndividualAccount}
        >
          Individual account
        </StyledTab>
      </FlexRow>
      <FlexRow gap={screenType === 'mobile' ? ROW_GAP_SM : ROW_GAP_MD} direction={'column'}>
        <Input
          value={name}
          label={<BodyText size={1}>Full name</BodyText>}
          onChange={handleName}
          error={!!formErrors.name}
          validationText={formErrors.name}
          onBlur={handleOnBlurName}
        />
        <Input
          value={email}
          label={<BodyText size={1}>Email</BodyText>}
          validationType={InputValidationType.email}
          onChange={handleEmail}
          error={!!formErrors.email}
          validationText={formErrors.email}
          onBlur={handleOnBlurEmail}
        />
        <Input
          value={password}
          label={<BodyText size={1}>Password</BodyText>}
          validationType={InputValidationType.password}
          onChange={handlePassword}
          error={!!formErrors.password}
          validationText={formErrors.password}
          onBlur={handleOnBlurPassword}
        />

        {password && <PasswordHints password={password} />}

        {activeCompanyButton && (
          <Input
            value={companyName}
            label={<BodyText size={1}>Company name</BodyText>}
            onChange={handleCompanyName}
            error={!!formErrors.companyName}
            validationText={formErrors.companyName}
            onBlur={handleOnBlurCompanyName}
          />
        )}
      </FlexRow>
      <FlexRow gap={screenType === 'mobile' ? ROW_GAP_MD : ROW_GAP_LG} direction={'column'}>
        <StyledRecaptcha
          recaptchaError={reCaptchaApiError || reCaptchaErrorText}
          sitekey={`${process.env.REACT_APP_SITE_KEY}`}
          onChange={handleReCaptcha}
          ref={(el) => {
            captcha = el;
          }}
        />
        {(reCaptchaApiError || reCaptchaErrorText) && (
          <StyledErrorText size={3}>{reCaptchaApiError || reCaptchaErrorText}</StyledErrorText>
        )}
      </FlexRow>
      <FlexRow gap={ROW_GAP_MD} direction={'column'}>
        <Checkbox
          checked={checkboxValue}
          label={
            <span>
              I acknowledge that I have read, understood, and accepted CSPR.build{' '}
              <StyledA to="/privacy-policy" target="_blank">
                {' '}
                Privacy Policy{' '}
              </StyledA>{' '}
              and{' '}
              <StyledA to="/terms-of-service" target="_blank">
                Terms of Service
              </StyledA>
              .
            </span>
          }
          customUncheckedColor={checkboxErrorText && ERROR_COLOR}
          onChange={handleCheckbox}
          checkboxFontSize={CheckboxFontSize.small}
        />
        {checkboxErrorText && (
          <StyledErrorCheckboxText size={3}>{checkboxErrorText}</StyledErrorCheckboxText>
        )}

        <Button color={'primaryRed'} onClick={handleSubmit}>
          Create account
        </Button>
        <BodyText size={3}>
          Already have an account?{' '}
          <Link to={SIGN_IN_PATH}>
            <BodyText size={1}>Log in →</BodyText>
          </Link>
        </BodyText>
      </FlexRow>
    </ContentWindow>
  );
};
