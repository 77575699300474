import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  BodyText,
  Button,
  Checkbox,
  CheckboxFontSize,
  FlexColumn,
  FlexRow,
  HeaderText,
  Input,
  InputValidationType,
  SvgIcon,
  useMatchMedia
} from '@make-software/cspr-ui';
import AccountLogo from 'assets/logos/creating-account.svg';
import { FORGOT_PASSWORD_PATH, SIGN_UP_PATH } from 'app/route/paths';
import { PASSWORD_LENGTH, ROW_GAP_MD } from 'app/constants';
import { invalidEmailMessage, invalidPasswordMessage, validateEmail } from 'app/validators';
import { SignInRequestData } from 'app/build-api';

interface SignInFormProps {
  onSubmit: (data: SignInRequestData) => Promise<void>;
  expectedError: string;
}

const ContentWindow = styled(FlexColumn)(({ theme }) =>
  theme.withMedia({
    display: 'flex',
    padding: '48px',
    gap: ['28px', '40px', '40px'],
    width: ['275px', '300px', '350px', '577px'],
    borderRadius: '4px',
    boxShadow: '0px 2px 4px 0px rgba(132, 134, 140, 0.12)',
    backgroundColor: theme.styleguideColors.backgroundPrimary
  })
);

export const SignInForm = ({ onSubmit, expectedError }: SignInFormProps) => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [rememberMe, setRememberMe] = useState<boolean>(false);

  const [formErrors, setFormErrors] = useState<Record<'email' | 'password', string | null>>({
    email: '',
    password: null
  });

  useEffect(() => {
    if (expectedError) {
      setPassword('');
      setFormErrors({
        ...formErrors,
        email: '',
        password: expectedError
      });
    }
  }, [expectedError]);

  const screenType = useMatchMedia(['mobile', 'tablet', 'desktop'], []);

  const handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value;
    setEmail(email);
  };

  const handleOnBlurEmail = () => {
    setFormErrors({
      ...formErrors,
      email: !validateEmail(email) ? invalidEmailMessage : ''
    });
  };

  const handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const pass = event.target.value;
    setPassword(pass);
  };

  const handleOnBlurPassword = () => {
    setFormErrors({
      ...formErrors,
      password: password.length < PASSWORD_LENGTH ? invalidPasswordMessage : ''
    });
  };

  const handleCheckbox = (value: boolean) => {
    setRememberMe(value);
  };

  const handleSubmit = () => {
    setFormErrors({
      ...formErrors,
      email: !email.length ? 'Please enter email address' : '',
      password: !password.length ? 'Please enter password' : ''
    });

    handleOnBlurPassword();
    handleOnBlurEmail();

    const isValidForm =
      Boolean(!formErrors.password) &&
      password.length &&
      Boolean(!formErrors.email) &&
      email.length;

    isValidForm && onSubmit && onSubmit({ email, password, rememberMe });
  };

  return (
    <ContentWindow>
      <SvgIcon src={AccountLogo} width={289} height={114} />
      <HeaderText size={3} scale={'md'}>
        Login
      </HeaderText>

      <FlexRow gap={ROW_GAP_MD} direction={'column'}>
        <Input
          value={email}
          label={<BodyText size={1}>Email</BodyText>}
          validationType={InputValidationType.email}
          onChange={handleEmail}
          onBlur={handleOnBlurEmail}
          error={!!formErrors.email}
          validationText={formErrors.email}
        />
        <Input
          value={password}
          label={<BodyText size={1}>Password</BodyText>}
          validationType={InputValidationType.password}
          onChange={handlePassword}
          onBlur={handleOnBlurPassword}
          error={!!formErrors.password}
          validationText={formErrors.password}
        />

        <BodyText size={1} scale={'sm'}>
          <Link to={FORGOT_PASSWORD_PATH}>Forgot your password?</Link>
        </BodyText>
      </FlexRow>

      <FlexRow gap={ROW_GAP_MD} direction={'column'}>
        <Checkbox
          checked={rememberMe}
          label="Remember me"
          onChange={handleCheckbox}
          checkboxFontSize={CheckboxFontSize.small}
        />

        <Button color={'primaryRed'} onClick={handleSubmit}>
          Login now
        </Button>
        <BodyText size={3}>
          Don’t have an account yet?{' '}
          <Link to={SIGN_UP_PATH}>
            <BodyText size={1} scale={screenType === 'mobile' ? 'xs' : 'sm'}>
              Sign up for free →
            </BodyText>
          </Link>
        </BodyText>
      </FlexRow>
    </ContentWindow>
  );
};
