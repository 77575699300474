import { DateRange, DateRangeKey, Period } from '../common';

export enum ClickInputFilters {
  apps = 'apps',
  providers = 'providers'
}

export interface ClickFilterState {
  [DateRangeKey]: DateRange;
  [ClickInputFilters.apps]: string[];
  [ClickInputFilters.providers]: string[];
}

export enum ClickGroupByTypes {
  'App' = 'App',
  'Provider' = 'Provider'
}

export enum ClickGroupBy {
  App = 'app',
  Provider = 'provider'
}

export interface ClickApiGraphFilter {
  period: Period;
  groupBy: ClickGroupBy;
}

export interface ClickSessionCountGraphFilter {
  clickSessionsCountPeriod: Period;
  clickSessionsCountGroupBy: ClickGroupBy;
}

export interface ClickUniqueAccountsCountGraphFilter {
  clickUniqueAccountsCountPeriod: Period;
  clickUniqueAccountsCountGroupBy: ClickGroupBy;
}

export const clickFiltersSearchParams: Record<string, string> = {
  [ClickInputFilters.apps]: 'apps',
  [ClickInputFilters.providers]: 'providers'
};
