import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';
import { Button, FlexBox, FlexColumn } from '@make-software/cspr-ui';
import {
  CloudApiItem,
  CloudFiltersState,
  CloudInputFilters,
  DateRange,
  DateRangeKey,
  GetCloudKeyResponseType
} from 'app/build-api';
import { GetResponseType } from 'app/build-api/types';
import { SkeletonLoader } from 'app/components/common/skeleton-loader/skeleton-loader';
import { AUTH_TOKEN, statusCode } from 'app/constants';
import { SIGN_IN_PATH } from 'app/route/paths';
import useManageEndpointsList from './hooks/useManageEndpointsList';
import {
  formatApisDataToMultiSelect,
  formatEndpointsDataToMultiSelect,
  formatKeysDataToMultiSelect,
  formatStatusesDataToMultiSelect
} from './utils/multiselect-formatters';
import { MultiSelectInput } from 'app/components/common/multiselect-input/multiselect-input';
import {
  DropdownEventValue,
  MultiSelectDropdown
} from 'app/components/common/multiselect-input/dropdown';
import { cloudStatuses } from './constants/constants';
import {
  getDefaultFilters,
  getDefaultSelectedFilters,
  getPreselectedFilters,
  SelectedCloudFiltersState,
  SelectedCloudFilterType
} from './utils/initial-filter-state-getter';
import { CloudEndpointItem } from 'app/build-api/statistics/cloud-logs/endpoints-list';
import { Calendar, CalendarRef } from 'app/components/common/calendar/calendar';

const StyledContainer = styled(FlexColumn)(({ theme }) =>
  theme.withMedia({
    padding: ['20px 16px', '0 150px', '0 200px', '0 280px'],
    '& > * + *': {
      marginTop: 28
    }
  })
);

const LoadingContainer = styled(StyledContainer)(() => ({
  marginTop: 20
}));

const StyledButtonContainer = styled(FlexBox)(({ theme }) =>
  theme.withMedia({
    flexDirection: ['column', 'row', 'row', 'row'],
    marginTop: [28, 28, 40, 40],
    '& > *': {
      width: ['100%', 'auto', 'auto', 'auto']
    },
    '& > * + *': {
      marginLeft: [0, 16, 16, 16],
      marginTop: [16, 0, 0, 0]
    }
  })
);

const StyledButton = styled(Button)(() => ({
  height: '36px'
}));

const getSelectedItemIds = (selectedItems: SelectedCloudFilterType[]) =>
  selectedItems.map((item) => item.id.toString());

interface FiltersBlockProps extends FiltersBlockContainerProps {
  fetchedEndpoints: CloudEndpointItem[] | undefined | null;
  handleChangeSearchValue: (value: string) => void;
}

export const FiltersBlock: React.FC<FiltersBlockProps> = ({
  filters,
  apiList,
  keyList,
  onUpdateFilters,
  fetchedEndpoints,
  handleChangeSearchValue
}) => {
  const calendarRef = useRef<CalendarRef>();
  const keys = formatKeysDataToMultiSelect(keyList.data?.data || []);
  const apis = formatApisDataToMultiSelect(apiList.data || []);
  const statuses = formatStatusesDataToMultiSelect(cloudStatuses);
  const endpoints = formatEndpointsDataToMultiSelect(fetchedEndpoints || []);

  const preselectedFilters = getPreselectedFilters({
    filters,
    keys,
    apis,
    statuses
  });

  const [selectedFilters, setSelectedFilters] =
    useState<SelectedCloudFiltersState>(preselectedFilters);

  const handleSelectItems = (event: DropdownEventValue, key: CloudInputFilters) => {
    const selectedItems = event.target.value;

    if (selectedItems) {
      setSelectedFilters({
        ...selectedFilters,
        [key]: selectedItems
      });
    }
  };

  const handleRemoveAllItems = (key: CloudInputFilters) => {
    setSelectedFilters({
      ...selectedFilters,
      [key]: []
    });
  };

  const handleSelectDateRange = (newDateRange: DateRange) => {
    onUpdateFilters({ ...filters, dateRange: newDateRange });
  };

  const handleApplyFilters = () => {
    onUpdateFilters({
      keys: getSelectedItemIds(selectedFilters.keys),
      apis: getSelectedItemIds(selectedFilters.apis),
      endpoints: getSelectedItemIds(selectedFilters.endpoints),
      statuses: getSelectedItemIds(selectedFilters.statuses)
    });
  };

  const handleResetFilters = () => {
    if (calendarRef.current) {
      calendarRef.current.resetState();
    }
    setSelectedFilters(getDefaultSelectedFilters());
    onUpdateFilters(getDefaultFilters());
  };

  return (
    <StyledContainer>
      <Calendar
        ref={calendarRef}
        dateRange={filters[DateRangeKey]}
        onDateRangeChange={handleSelectDateRange}
      />

      <MultiSelectDropdown
        label={'Keys'}
        placeholder={'Select keys'}
        value={selectedFilters.keys}
        items={keys}
        onSelect={(event) => handleSelectItems(event, CloudInputFilters.keys)}
        onClearAllItems={(event) => handleRemoveAllItems(CloudInputFilters.keys)}
      />

      <MultiSelectDropdown
        label={'APIs'}
        placeholder={'Select APIs'}
        value={selectedFilters.apis}
        items={apis}
        onSelect={(event) => handleSelectItems(event, CloudInputFilters.apis)}
        onClearAllItems={(event) => handleRemoveAllItems(CloudInputFilters.apis)}
      />
      <MultiSelectInput
        label={'Endpoint'}
        placeholder={'Endpoint'}
        value={selectedFilters.endpoints}
        onSelectItem={(event) => handleSelectItems(event, CloudInputFilters.endpoints)}
        onChangeInput={handleChangeSearchValue}
        items={endpoints}
      />
      <MultiSelectDropdown
        label={'Status'}
        placeholder={'Select statuses'}
        value={selectedFilters.statuses}
        items={statuses}
        onSelect={(event) => handleSelectItems(event, CloudInputFilters.statuses)}
        onClearAllItems={(event) => handleRemoveAllItems(CloudInputFilters.statuses)}
      />
      <StyledButtonContainer>
        <StyledButton width={'176'} color={'primaryBlue'} onClick={handleApplyFilters}>
          Apply Filters
        </StyledButton>
        <StyledButton width={'176'} color={'secondaryBlue'} onClick={handleResetFilters}>
          Reset Filters
        </StyledButton>
      </StyledButtonContainer>
    </StyledContainer>
  );
};

interface FiltersBlockContainerProps {
  filters: CloudFiltersState;
  apiList: GetResponseType<CloudApiItem[]>;
  keyList: GetResponseType<GetCloudKeyResponseType>;
  onUpdateFilters: (updatedFilters: Partial<CloudFiltersState>) => void;
}

export const FiltersBlockContainer: React.FC<FiltersBlockContainerProps> = (props) => {
  const navigate = useNavigate();
  const loading = props.apiList.loading || props.keyList.loading;
  const error = props.apiList.error || props.keyList.error;
  const httpCode = props.apiList.httpCode || props.keyList.httpCode;

  const {
    endpoints: fetchedEndpoints,
    popularEndpointsLoading,
    handleChangeSearchValue
  } = useManageEndpointsList();

  if (loading || popularEndpointsLoading) {
    return (
      <LoadingContainer>
        <SkeletonLoader height={'40px'} />
        <SkeletonLoader height={'40px'} />
        <SkeletonLoader height={'40px'} />
        <SkeletonLoader height={'40px'} />
        <SkeletonLoader height={'40px'} />
        <SkeletonLoader height={'40px'} />
      </LoadingContainer>
    );
  }

  if (error) {
    switch (httpCode) {
      case statusCode.invalid_input_error:
      case statusCode.access_denied_error:
      case statusCode.validation_error:
        Sentry.captureException(error);
        break;
      case statusCode.authentication_error:
        localStorage.removeItem(AUTH_TOKEN);
        navigate(SIGN_IN_PATH);
        break;
    }
  }
  return (
    <FiltersBlock
      {...props}
      fetchedEndpoints={fetchedEndpoints}
      handleChangeSearchValue={handleChangeSearchValue}
    />
  );
};
