import React from 'react';
import styled from 'styled-components';
import { DropdownValue } from '@make-software/cspr-ui/dist/lib/components/dropdown/dropdown';
import {
  ClickGroupBy,
  ClickGroupByTypes,
  ClickSessionCountGraphFilter,
  ClickUniqueAccountsCountGraphFilter,
  CloudGroupBy,
  CloudGroupByTypes,
  CloudRequestCountFilter,
  Period
} from 'app/build-api';
import {
  BodyText,
  Dropdown,
  FlexColumn,
  FlexRow,
  formatNumber,
  useMatchMedia
} from '@make-software/cspr-ui';
import { ButtonListSelect } from 'app/components/common/button-list-select/button-list-select';

const ChartHeaderContainer = styled(FlexColumn)(({ theme }) =>
  theme.withMedia({
    flexDirection: ['column', 'column', 'row', 'row'],
    width: '100%',
    justifyContent: 'space-between',
    '& > * + *': {
      marginTop: [16, 16, 0]
    }
  })
);

const ChartFiltersContainer = styled(FlexRow)(({ theme }) =>
  theme.withMedia({
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > * + *': {
      marginLeft: 32
    }
  })
);

const DropdownWrapper = styled.div(({ theme }) =>
  theme.withMedia({
    position: 'relative',

    '& > div[role="combobox"]': {
      width: '100%'
    },

    '& > div[role="combobox"] > div[role="button"]': {
      height: '30px'
    },

    '& > div[role="combobox"] > div[role="listbox"]': {
      position: 'absolute',
      zIndex: 1000,
      width: '100%'
    }
  })
);

export enum ChartType {
  'click' = 'click',
  'cloud' = 'cloud'
}

enum PeriodTypes {
  'Daily' = 'Daily',
  'Weekly' = 'Weekly',
  'Monthly' = 'Monthly'
}

export type ChartGraphFilter = {
  period: Period;
  groupBy: CloudGroupBy | ClickGroupBy;
};

export interface ChartHeaderProps {
  groupByValues: DropdownValue[];
  periodValues: DropdownValue[];
  totalRequests: number;
  graphFilters: ChartGraphFilter;
  onFiltersChange: (filters: Partial<ChartGraphFilter>) => void;
  totalLabel?: string;
}

export const ChartHeader = ({
  groupByValues,
  periodValues,
  totalRequests,
  graphFilters,
  onFiltersChange,
  totalLabel
}: ChartHeaderProps) => {
  const screenType = useMatchMedia(['mobile', 'tabletOrAbove'], []);

  const groupDropdownValue =
    groupByValues.find((one) => graphFilters.groupBy == one.value) || groupByValues[1];

  const onGroupByChange = (item: any) => {
    onFiltersChange({ groupBy: item.value });
  };

  const onPeriodChange = (item: DropdownValue) => {
    onFiltersChange({ period: item.value as Period });
  };

  return (
    <ChartHeaderContainer>
      <FlexRow itemsSpacing={4} align="center">
        <BodyText size={3} scale={'md'} variation={'darkGray'}>
          {totalLabel || 'Total'}:
        </BodyText>

        <BodyText size={2} scale={'md'} variation={'black'}>
          {formatNumber(totalRequests)}
        </BodyText>
      </FlexRow>
      <ChartFiltersContainer>
        <FlexRow justify={'start'} align="center" gap={8}>
          {screenType !== 'mobile' && (
            <BodyText size={3} scale={'xs'} variation={'darkGray'}>
              Group by:
            </BodyText>
          )}
          <DropdownWrapper>
            <Dropdown
              items={groupByValues}
              value={groupDropdownValue}
              onSelect={onGroupByChange}
              style={{ width: 120 }}
            />
          </DropdownWrapper>
        </FlexRow>
        <FlexRow justify={'space-between'} gap={4}>
          {periodValues.map((item) => (
            <ButtonListSelect
              onClick={() => onPeriodChange(item)}
              value={item.label}
              active={item.value == graphFilters.period}
              key={item.value}
            />
          ))}
        </FlexRow>
      </ChartFiltersContainer>
    </ChartHeaderContainer>
  );
};

export const periodValues: DropdownValue[] = [
  { label: PeriodTypes.Daily, value: Period.Day },
  { label: PeriodTypes.Weekly, value: Period.Week },
  { label: PeriodTypes.Monthly, value: Period.Month }
];

export const cloudGroupByValues: DropdownValue[] = [
  { label: CloudGroupByTypes.API, value: CloudGroupBy.Api },
  { label: CloudGroupByTypes.Key, value: CloudGroupBy.Key },
  { label: CloudGroupByTypes.Status, value: CloudGroupBy.Status }
];

type RequestCountChartHeaderProps = {
  totalRequests: number;
  onFiltersChange: (filters: Partial<ChartGraphFilter>) => void;
  graphFilters: CloudRequestCountFilter;
};

export const RequestCountChartHeader = (props: RequestCountChartHeaderProps) => {
  return (
    <ChartHeader
      {...props}
      groupByValues={cloudGroupByValues}
      periodValues={periodValues}
      totalLabel={'Total requests'}
    />
  );
};

export const clickGroupByValues: DropdownValue[] = [
  { label: ClickGroupByTypes.App, value: ClickGroupBy.App },
  { label: ClickGroupByTypes.Provider, value: ClickGroupBy.Provider }
];

type SessionCountChartHeaderProps = {
  totalRequests: number;
  onFiltersChange: (filters: Partial<ChartGraphFilter>) => void;
  graphFilters: ClickSessionCountGraphFilter;
};

export const SessionCountChartHeader = (props: SessionCountChartHeaderProps) => {
  const graphFilters = {
    groupBy: props.graphFilters.clickSessionsCountGroupBy,
    period: props.graphFilters.clickSessionsCountPeriod
  };

  return (
    <ChartHeader
      {...props}
      groupByValues={clickGroupByValues}
      periodValues={periodValues}
      graphFilters={graphFilters}
      totalLabel={'Total session count'}
    />
  );
};

type UniqueAccountsCountChartHeaderProps = {
  totalRequests: number;
  onFiltersChange: (filters: Partial<ChartGraphFilter>) => void;
  graphFilters: ClickUniqueAccountsCountGraphFilter;
};

export const UniqueAccountsChartHeader = (props: UniqueAccountsCountChartHeaderProps) => {
  const graphFilters = {
    groupBy: props.graphFilters.clickUniqueAccountsCountGroupBy,
    period: props.graphFilters.clickUniqueAccountsCountPeriod
  };

  return (
    <ChartHeader
      {...props}
      groupByValues={clickGroupByValues}
      periodValues={periodValues}
      graphFilters={graphFilters}
      totalLabel={'Total unique accounts'}
    />
  );
};
