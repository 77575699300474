import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import styled, { useTheme } from 'styled-components';
import Switch from 'react-switch';
import { useNavigate } from 'react-router-dom';
import {
  BodyText,
  Button,
  FlexRow,
  SearchableDropdown,
  SvgIcon,
  useClickAway
} from '@make-software/cspr-ui';
import {
  ButtonsContainer,
  centerModalStyles,
  ErrorText,
  ErrorTextContainer,
  ModalContainer,
  StyledCaption,
  StyledCaptionText,
  StyledConfirmButton,
  StyledDismissButton,
  StyledInput,
  StyledToggle,
  TOGGLE_ACTIVE_COLOR
} from 'app/components/home/components/create-modal-styles';
import { checkMaxLength, validateDomain } from 'app/validators';
import { ErrorWindow } from 'app/components/common/error-window/error-window';
import NotFoundIcon from 'assets/logos/no-found.svg';
import { ClickKeyResponseType, ClickKeyStatus, UpdateClickKeyRequestType } from 'app/build-api';
import { AUTH_TOKEN, CASPER, CASPER_TEST, MAINNET, statusCode, TESTNET } from 'app/constants';
import { ExpectedErrors } from 'app/components/home/components/click-keys/click-key-container';
import { ErrorResult } from 'app/build-api/types';
import { useGetCloudKeys } from 'app/build-api/hooks/useGetCloudKeys';
import { SIGN_IN_PATH } from 'app/route/paths';

export interface DropdownItem {
  label: string;
  value: string;
}

export interface CreateClickKeyWindowProps {
  isOpen: boolean;
  keyToEdit: ClickKeyResponseType;
  title: string;
  confirmLabel: string;
  onUpdate: (id: string, data: UpdateClickKeyRequestType) => void;
  dismissLabel?: string;
  onDismiss: () => void;
  expectedError?: ExpectedErrors | undefined;
  unexpectedError?: ErrorResult | null;
  portalClass?: string;
}

const NETWORKS = [
  { value: MAINNET, label: CASPER },
  { value: TESTNET, label: CASPER_TEST }
];

const StyledAddDomain = styled(FlexRow)(({ theme }) =>
  theme.withMedia({
    cursor: 'pointer'
  })
);

const StyledButton = styled(Button)(({ theme }) =>
  theme.withMedia({
    height: '30px'
  })
);

const DomainsTitle = styled(BodyText)(({ theme }) =>
  theme.withMedia({
    position: 'relative',
    top: '15px'
  })
);

export const EditClickKeyWindow = ({
  isOpen,
  title,
  keyToEdit,
  confirmLabel,
  onUpdate,
  dismissLabel,
  onDismiss,
  unexpectedError,
  expectedError,
  portalClass = 'portal'
}: CreateClickKeyWindowProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const modalStyle = {
    overlay: {
      backgroundColor: theme.styleguideColors.backgroundOverlay,
      zIndex: 15
    },
    content: {
      ...centerModalStyles,
      ...{
        border: 'none',
        backgroundColor: theme.styleguideColors.backgroundPrimary,
        borderTop: `4px solid ${theme.styleguideColors.contentRed}`,
        borderColor: theme.styleguideColors.contentRed,
        boxShadow: '0px 16px 48px rgba(26, 25, 25, 0.2)'
      }
    }
  };

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'unset';
  }, [isOpen]);

  const {
    id,
    name,
    status,
    domains: domainsToEdit,
    app_id,
    network: networkToEdit,
    linked_cloud_key
  } = keyToEdit;
  const incomeDomains = domainsToEdit.map((domain) => {
    return { value: domain };
  });
  const incomeNetwork = networkToEdit === MAINNET ? NETWORKS[0] : NETWORKS[1];

  const [showModal, setShowModal] = useState<boolean>(false);
  const [keyName, setKeyName] = useState<string>(name);
  const [isStatusActive, setIsStatusActive] = useState<boolean>(status === ClickKeyStatus.ACTIVE);
  const [network, setNetwork] = useState<DropdownItem>(incomeNetwork);
  const [domains, setDomains] = useState<{ value: string }[]>(incomeDomains);
  const [formErrors, setFormErrors] = useState<Record<'keyName' | 'domain', string | null>>({
    keyName: '',
    domain: ''
  });

  const { data: cloudKeys, error: fetchingKeysError, httpCode } = useGetCloudKeys(1, 100);
  const [linkedCloudKey, setLinkedCloudKey] = useState<DropdownItem>({
    value: linked_cloud_key.id,
    label: linked_cloud_key.name
  });
  const [linkedCloudKeysList, setLinkedCloudKeysList] = useState<DropdownItem[]>([
    { value: '', label: '' }
  ]);

  useEffect(() => {
    if (cloudKeys?.data) {
      const formattedKeys = cloudKeys?.data.map((k) => {
        const hashStart = k.hash.slice(0, 4);
        const hashEnd = k.hash.slice(k.hash.length - 4);
        return {
          value: k.id,
          label: `${k.name}, ${hashStart}...${hashEnd}`
        };
      });

      setLinkedCloudKeysList(formattedKeys);
      setLinkedCloudKey(formattedKeys[0]);
    }
  }, [cloudKeys]);

  if (fetchingKeysError && httpCode === statusCode.authentication_error) {
    localStorage.removeItem(AUTH_TOKEN);
    navigate(SIGN_IN_PATH);
  }

  const clearForm = () => {
    setKeyName(name);
    setNetwork(incomeNetwork);
    setDomains(incomeDomains);
    setIsStatusActive(status === ClickKeyStatus.ACTIVE);
    setFormErrors({
      ...formErrors,
      keyName: '',
      domain: ''
    });
  };

  useEffect(() => {
    setShowModal(isOpen);
    clearForm();
  }, [isOpen]);

  const { ref } = useClickAway({
    callback: () => {
      clearForm();
      onDismiss();
    }
  });

  const handleKeyName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    setKeyName(val);
  };

  const handleKeyNameOnBlur = () => {
    setFormErrors({
      ...formErrors,
      keyName: !keyName.length ? 'This field is required' : checkMaxLength(keyName)
    });
  };

  const handleLinkCloudKey = (linkedKey: DropdownItem) => {
    setLinkedCloudKey(linkedKey);
  };

  const handleAddDomain = () => {
    if (domains.length >= 5) return;
    setDomains([...domains, { value: '' }]);
  };

  const handleDomainOnBlur = (index: number) => {
    const data: any = [...domains];
    const value = data[index].value;
    setFormErrors({
      ...formErrors,
      domain: !value.length ? 'This field is required' : validateDomain(value)
    });
  };

  const handleConfirm = () => {
    if (!keyName.length) {
      setFormErrors({
        ...formErrors,
        keyName: 'This field is required'
      });
      return;
    }

    if (!domains[0].value) {
      setFormErrors({
        ...formErrors,
        domain: 'This field is required'
      });
      return;
    }

    const invalidDomain = domains
      .map((d) => d.value)
      .filter((d) => d)
      .filter((d) => validateDomain(d));

    !formErrors.keyName &&
      !formErrors.domain &&
      !invalidDomain.length &&
      onUpdate &&
      onUpdate(id, {
        network: network.value,
        name: keyName,
        domains: domains.map((d) => d.value).filter((d) => d),
        status: isStatusActive ? ClickKeyStatus.ACTIVE : ClickKeyStatus.INACTIVE,
        cloud_key_id: linkedCloudKey.value
      });
  };

  const handleOnDismiss = () => {
    clearForm();
    onDismiss();
  };

  const handleChangeKeyStatus = () => {
    setIsStatusActive((prevState) => !prevState);
  };

  const handleChangeDomain = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = event.target;
    const data: any = [...domains];
    data[index].value = value;
    setDomains(data);
  };

  const handleDelete = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const data = [...domains];
    data.splice(index, 1);
    setDomains(data);
  };

  return (
    <>
      {showModal && (
        <ReactModal
          isOpen={showModal}
          style={modalStyle}
          shouldCloseOnEsc
          shouldCloseOnOverlayClick
          portalClassName={portalClass}
        >
          <ModalContainer ref={ref} gap={20}>
            {unexpectedError ? (
              <ErrorWindow
                title={unexpectedError.code || ''}
                information={unexpectedError.message}
                withModal
                icon={<SvgIcon src={NotFoundIcon} width={219} height={125} />}
              />
            ) : (
              <>
                <StyledCaption>
                  <StyledCaptionText size={1} scale="lg">
                    {title}
                  </StyledCaptionText>
                </StyledCaption>

                <>
                  <FlexRow justify="center">
                    <StyledInput
                      value={app_id}
                      label={<BodyText size={1}>AppID</BodyText>}
                      placeholder="AppID"
                      disabled
                      withBorder
                    />
                  </FlexRow>
                  <FlexRow justify="center">
                    <StyledInput
                      value={keyName}
                      label={<BodyText size={1}>Name</BodyText>}
                      placeholder="Key name"
                      onChange={handleKeyName}
                      onBlur={handleKeyNameOnBlur}
                      error={!!formErrors.keyName}
                      validationText={formErrors.keyName}
                    />
                  </FlexRow>
                  <DomainsTitle size={1}>Domains (up to 5)</DomainsTitle>
                  {domains.map((d, index) => (
                    <>
                      <FlexRow justify={'center'} align={'center'} gap={5}>
                        <StyledInput
                          name={'domain'}
                          value={d.value}
                          placeholder="example.com"
                          onChange={(event) => handleChangeDomain(event, index)}
                          onBlur={() => index === 0 && handleDomainOnBlur(index)}
                          error={
                            index === 0
                              ? !!formErrors.domain
                              : Boolean(
                                  domains[index].value && validateDomain(domains[index].value)
                                )
                          }
                          validationText={
                            index === 0
                              ? formErrors.domain
                              : domains[index].value && validateDomain(domains[index].value)
                          }
                        />
                        {index !== 0 && (
                          <StyledButton
                            width={'100'}
                            color={'secondaryBlue'}
                            onClick={(event) => handleDelete(index, event)}
                          >
                            Remove
                          </StyledButton>
                        )}
                      </FlexRow>
                    </>
                  ))}
                  <BodyText
                    size={3}
                    scale={'sm'}
                    variation={domains.length === 5 ? 'gray' : 'blue'}
                  >
                    <StyledAddDomain onClick={handleAddDomain}>
                      + Add another domain
                    </StyledAddDomain>
                  </BodyText>
                  <FlexRow justify="center">
                    <StyledInput
                      value={incomeNetwork.label}
                      label={<BodyText size={1}>Network</BodyText>}
                      placeholder="Network"
                      disabled
                      withBorder
                    />
                  </FlexRow>
                  {expectedError && Object.keys(expectedError).length !== 0 && (
                    <ErrorTextContainer justify={'center'}>
                      <ErrorText size={3}>{expectedError.duplicateDomainError}</ErrorText>
                    </ErrorTextContainer>
                  )}
                  <BodyText size={1}>Connected CSPR.cloud key</BodyText>
                  <SearchableDropdown
                    value={linkedCloudKey}
                    items={linkedCloudKeysList}
                    onSelect={handleLinkCloudKey}
                    height={'auto'}
                    fontSize={'14px'}
                    maxHeight={'134px'}
                    placeholder={'Please link CSPR.cloud key...'}
                    isError={Boolean(fetchingKeysError)}
                  />
                </>

                <StyledToggle>
                  <BodyText size={1}>Active status:</BodyText>
                  <Switch
                    checked={isStatusActive}
                    onChange={handleChangeKeyStatus}
                    onColor={TOGGLE_ACTIVE_COLOR}
                    checkedIcon={false}
                    uncheckedIcon={false}
                  />
                </StyledToggle>
                <ButtonsContainer justify={'space-between'}>
                  {dismissLabel && (
                    <StyledDismissButton color={'utility'} onClick={handleOnDismiss}>
                      {dismissLabel}
                    </StyledDismissButton>
                  )}
                  <StyledConfirmButton onClick={handleConfirm}>{confirmLabel}</StyledConfirmButton>
                </ButtonsContainer>
              </>
            )}
          </ModalContainer>
        </ReactModal>
      )}
    </>
  );
};

export default EditClickKeyWindow;
