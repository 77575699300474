import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';
import { PageTile } from '@make-software/cspr-ui';
import { CloudApiItem } from 'app/build-api/statistics/cloud-logs/api-list';
import { buildApi, DefaultResponse, GetResponseType } from 'app/build-api/types';
import { CloudFiltersState, GetCloudKeyResponseType } from 'app/build-api';
import {
  getCloudFiltersFromSearchParams,
  getSearchParamsFromFilters
} from 'app/components/utils/url-utils';
import { ActiveTabs, TabData } from '../common/active-tabs/active-tabs';
import { RequestsLogList } from './request-log-table/requests-log-list';
import { RequestCountContainer } from './request-count-graph/request-count-container';
import { FiltersBlockContainer } from './filters-block';

const FiltersContent = styled(PageTile)(({ theme }) =>
  theme.withMedia({
    padding: '32px 0px'
  })
);

interface CloudStatisticsContainerProps {
  activeTab: TabData;
  handleChangeTab: (tab: TabData) => void;
}

export const CloudStatisticsContainer = (props: CloudStatisticsContainerProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState<CloudFiltersState>(
    getCloudFiltersFromSearchParams(searchParams)
  );
  const [apiList, setApiList] = useState<GetResponseType<CloudApiItem[]>>(DefaultResponse);
  const [keyList, setKeyList] = useState<GetResponseType<GetCloudKeyResponseType>>(DefaultResponse);

  useEffect(() => {
    (async () => {
      setApiList(await buildApi.getCloudApiList());
      setKeyList(await buildApi.getCloudKeys());
    })();
  }, []);

  const handleUpdateFilters = (updatedFilters: Partial<CloudFiltersState>) => {
    const newState = {
      ...filters,
      ...updatedFilters
    };
    setSearchParams((prevParams) => {
      return getSearchParamsFromFilters(prevParams, newState);
    });
    setFilters(newState);
  };

  return (
    <>
      <FiltersContent>
        <ActiveTabs activeTab={props.activeTab} onChange={props.handleChangeTab} />
        <FiltersBlockContainer
          filters={filters}
          apiList={apiList}
          keyList={keyList}
          onUpdateFilters={handleUpdateFilters}
        />
      </FiltersContent>
      <RequestCountContainer filters={filters} apiList={apiList} keyList={keyList} />
      <RequestsLogList filters={filters} apiList={apiList} keyList={keyList} />
    </>
  );
};
