import React, { useEffect, useState } from 'react';
import {
  ClickFilterState,
  CountHistogramResponse,
  GetClickKeyResponseType,
  ClickGroupBy,
  ClickSessionCountGraphFilter
} from 'app/build-api';
import { buildApi, DefaultResponse, GetResponseType } from 'app/build-api/types';
import { EntityCountChart } from '../../common/charts/entity-count-chart';
import { FailedToFetch } from 'app/components/table/table-error';
import {
  ChartLegendsType,
  getChartValuesDataWithLabels,
  getRequestCount,
  prepareAppListDataToChartLegends,
  prepareProvidersListDataToChartLegends
} from '../../common/charts/chart-formatters';
import { ClickProviders } from '../constants/constants';
import { useSearchParams } from 'react-router-dom';
import {
  getClickSessionGraphFiltersFromSearchParams,
  getSearchParamsFromSessionGraphFilters
} from 'app/components/utils/url-utils';
import { ChartGraphFilter, SessionCountChartHeader } from '../../common/charts/chart-header';

export interface SessionCountContainerProps {
  filters: ClickFilterState;
  appsList: GetResponseType<GetClickKeyResponseType>;
}

export const SessionCountContainer = ({ filters, appsList }: SessionCountContainerProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [graphFilters, setGraphFilters] = useState<ClickSessionCountGraphFilter>(
    getClickSessionGraphFiltersFromSearchParams(searchParams)
  );
  const [histogramData, setHistogramData] =
    useState<GetResponseType<CountHistogramResponse>>(DefaultResponse);
  const [chartLegendsData, setChartLegendsData] = useState<ChartLegendsType[]>([]);

  useEffect(() => {
    (async () => {
      const res = await buildApi.getClickSessionCountHistogram(filters, {
        groupBy: graphFilters.clickSessionsCountGroupBy,
        period: graphFilters.clickSessionsCountPeriod
      });
      setHistogramData(res);
    })();
  }, [filters, graphFilters]);

  const handleGraphFiltersChange = (updatedFilters: Partial<ChartGraphFilter>) => {
    const newState = {
      ...graphFilters,
      ...(updatedFilters.period && {
        clickSessionsCountPeriod: updatedFilters.period
      }),
      ...(updatedFilters.groupBy && {
        clickSessionsCountGroupBy: updatedFilters.groupBy
      })
    };
    setSearchParams((prevParams) => {
      const currentParams = new URLSearchParams(prevParams);
      return getSearchParamsFromSessionGraphFilters(
        currentParams,
        newState as ClickSessionCountGraphFilter
      );
    });
    setGraphFilters(newState as ClickSessionCountGraphFilter);
  };

  const formattedAppListData = prepareAppListDataToChartLegends(appsList?.data?.data || []);
  const formattedProvidersListData = prepareProvidersListDataToChartLegends(ClickProviders || []);

  useEffect(() => {
    switch (graphFilters.clickSessionsCountGroupBy) {
      case ClickGroupBy.App:
        setChartLegendsData(formattedAppListData);
        break;
      case ClickGroupBy.Provider:
        setChartLegendsData(formattedProvidersListData);
        break;
      default:
        setChartLegendsData(formattedAppListData);
        break;
    }
  }, [graphFilters.clickSessionsCountGroupBy, graphFilters.clickSessionsCountPeriod, appsList]);

  const yAxisDataById = histogramData.data?.histogram ? getRequestCount(histogramData.data) : [];

  const sessionCountChartData = getChartValuesDataWithLabels(chartLegendsData, yAxisDataById);

  const loading = appsList.loading || histogramData.loading;
  const error = appsList.error || histogramData.error;

  return (
    <EntityCountChart
      chartHeader={
        <SessionCountChartHeader
          totalRequests={histogramData?.data?.total || 0}
          graphFilters={graphFilters}
          onFiltersChange={handleGraphFiltersChange}
        />
      }
      title={'Session Count'}
      yAxisLabelText={'Session Count'}
      data={sessionCountChartData}
      loading={loading}
      error={error ? <FailedToFetch error={error} /> : null}
    />
  );
};
