import { GetResponseType } from '../types';
import useApi from '../hooks/useApi';
import { AUTH_TOKEN, WITH_AUTH } from 'app/constants';
import { decodeJWT } from 'app/components/utils/utils';
import { CloudKeyResponseType } from '../cloud-key/cloud-key';

interface RequestType {
  name: string;
  domains: string[];
  network: string;
  cloud_key_id: string;
  status: ClickKeyStatus;
}

export interface ClickKeyRequestType extends RequestType {}

export interface UpdateClickKeyRequestType extends RequestType {}

export interface ClickKeyResponseType {
  id: string;
  organization_id: string;
  app_id: string;
  name: string;
  api_key: string;
  domains: string[];
  network: string;
  linked_cloud_key: CloudKeyResponseType;
  status: string;
  created_at: string;
  updated_at: string;
}

export interface GetClickKeyResponseType {
  item_count: number;
  page_count: number;
  data: ClickKeyResponseType[] | null;
}

export enum ClickKeyStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export const createClickKey = async (
  payload: ClickKeyRequestType
): Promise<GetResponseType<ClickKeyResponseType>> => {
  const decodedJwt = JSON.parse(decodeJWT(localStorage.getItem(AUTH_TOKEN) || ''));
  const { data, error, loading, httpCode } = await useApi(
    `${process.env.REACT_APP_CSPRBUILD_API_ENDPOINT}/organizations/${decodedJwt.organizationID}/click-keys`,
    'POST',
    payload,
    {},
    WITH_AUTH
  );
  return {
    data,
    httpCode,
    error,
    loading
  };
};

export const getClickKeys = async (
  page?: number,
  pageSize?: number
): Promise<GetResponseType<GetClickKeyResponseType>> => {
  const decodedJwt = JSON.parse(decodeJWT(localStorage.getItem(AUTH_TOKEN) || ''));
  const getPageOption = () => {
    if (!page && !pageSize) return '';
    if (page && pageSize) return `?page=${page}&page_size=${pageSize}`;
    if (!page && pageSize) return `?page_size=${pageSize}`;
    if (page && !pageSize) return `?page=${page}`;
  };

  const { data, error, loading, httpCode } = await useApi(
    `${process.env.REACT_APP_CSPRBUILD_API_ENDPOINT}/organizations/${decodedJwt.organizationID}/click-keys${getPageOption()}`,
    'GET',
    null,
    {},
    WITH_AUTH
  );
  return {
    data,
    httpCode,
    error,
    loading
  };
};

export const updateClickKey = async (
  id: string,
  payload: UpdateClickKeyRequestType
): Promise<GetResponseType<ClickKeyResponseType>> => {
  const decodedJwt = JSON.parse(decodeJWT(localStorage.getItem(AUTH_TOKEN) || ''));
  const { data, error, loading, httpCode } = await useApi(
    `${process.env.REACT_APP_CSPRBUILD_API_ENDPOINT}/organizations/${decodedJwt.organizationID}/click-keys/${id}`,
    'PUT',
    payload,
    {},
    WITH_AUTH
  );
  return {
    data,
    httpCode,
    error,
    loading
  };
};
