import { HashLength } from '@make-software/cspr-ui';
import { CloudApiItem, CloudKeyResponseType } from 'app/build-api';
import { formatKeysHash } from 'app/components/utils/measures-format';
import { CloudEndpointItem } from 'app/build-api/statistics/cloud-logs/endpoints-list';

export const formatKeysDataToMultiSelect = (data: CloudKeyResponseType[]) =>
  data.map((item) => {
    const chipLabel = `${formatKeysHash(item.hash, HashLength.TINY)}`;
    const label = `${item.name} ${formatKeysHash(item.hash, HashLength.TINY)}`;
    return {
      id: item.id,
      label,
      value: label,
      chipLabel,
      chipName: item.name
    };
  });

export const formatApisDataToMultiSelect = (data: CloudApiItem[]) =>
  data.map((item) => {
    return {
      id: item.id,
      label: item.name,
      value: item.name,
      chipLabel: item.name
    };
  });

export const formatEndpointsDataToMultiSelect = (data: CloudEndpointItem[]) =>
  data.map((item) => {
    return {
      id: item.key,
      label: item.key,
      value: item.key,
      chipLabel: item.key
    };
  });

export const formatStatusesDataToMultiSelect = (
  data: {
    label: string;
    id: string;
  }[]
) =>
  data.map((item) => ({
    id: item.id,
    label: item.label,
    chipLabel: item.label,
    value: item.id
  }));
