import { Dropdown } from '@make-software/cspr-ui';
import React, { useEffect, useState } from 'react';
import { DateRange as CalendarDateRange } from 'app/build-api';
import { DropdownValue } from 'app/components/common/multiselect-input/dropdown';
import { UTCDate } from '@date-fns/utc';

export interface CustomSettingsSelectProps {
  dateRange: CalendarDateRange;
  onDateRangeChange: (newDateRange: CalendarDateRange) => void;
}

const getFastSelectValue = (range: CalendarDateRange): DropdownValue => {
  const now = new UTCDate();
  const isCurrentDateEqualRangeTo = now.toLocaleDateString() === range.to.toLocaleDateString();

  const diffInMilliseconds = range.to.getTime() - range.from.getTime();
  const diffInDays = isCurrentDateEqualRangeTo ? diffInMilliseconds / (1000 * 60 * 60 * 24) : 0;

  switch (diffInDays) {
    case 7:
      return { value: '7days', label: 'Last 7 days' };
    case 30:
      return { value: '30days', label: 'Last 30 days' };
    case 90:
      return { value: '90days', label: 'Last 90 days' };
    default:
      return { value: 'custom', label: 'Custom' };
  }
};

const getDateRangeByFastSelectValue = (value: string): CalendarDateRange => {
  const now = new UTCDate();
  switch (value) {
    case '7days':
      return { from: new UTCDate(now.getTime() - 7 * 24 * 60 * 60 * 1000), to: now };
    case '30days':
      return { from: new UTCDate(now.getTime() - 30 * 24 * 60 * 60 * 1000), to: now };
    case '90days':
      return { from: new UTCDate(now.getTime() - 90 * 24 * 60 * 60 * 1000), to: now };
    default:
      return { from: now, to: now };
  }
};

export const CustomSettingsSelect = (props: CustomSettingsSelectProps) => {
  const fastSelectOptions = [
    { value: '7days', label: 'Last 7 days' },
    { value: '30days', label: 'Last 30 days' },
    { value: '90days', label: 'Last 90 days' },
    { value: 'custom', label: 'Custom' }
  ];

  const [fastSelectState, setFastSelectState] = useState(getFastSelectValue(props.dateRange));

  const onFastSelectChange = (value: any) => {
    setFastSelectState(value);
    if (value.value === 'custom') {
      return;
    }
    props.onDateRangeChange(getDateRangeByFastSelectValue(value.value));
  };

  useEffect(() => {
    setFastSelectState(getFastSelectValue(props.dateRange));
  }, [props.dateRange]);

  return (
    <Dropdown
      label={'Date Range'}
      items={fastSelectOptions}
      value={fastSelectState}
      onSelect={onFastSelectChange}
    />
  );
};
